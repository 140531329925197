<template>
  <v-col class="px-0 py-0">
    <loader v-if="showLoader" />
    <v-data-table
      v-else
      style="margin-top: 32px"
      :headers="headers"
      :items="trips"
      hide-default-footer
      hide-default-header
      :items-per-page="100"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      background-color="red"
      no-data-text="Поки що ви не додали жодного маршруту"
    >
      <template v-slot:header="{ props }">
        <th
          @click="
            head.sortable && head.sortable !== false
              ? (sortBy = head.value)((sortDesc = !sortDesc))
              : ''
          "
          v-for="head in props.headers"
          :key="head.id"
          style="padding: 15px; text-align: center; vertical-align: middle"
        >
          <v-row
            no-gutters
            align="center"
            :justify="head.align ? head.align : 'start'"
            ><output
              style="
                color: #4b5262;
                text-align: center;
                font-family: 'MacPaw Fixel';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                user-select: none;
              "
              :style="head.sortable !== false ? 'cursor: pointer;' : ''"
              >{{ head.text }}
              <v-icon
                v-if="head.sortable !== false"
                size="25px"
                :color="head.value == sortBy ? 'black' : ''"
                >mdi-menu-swap</v-icon
              >
            </output>
          </v-row>
        </th>
      </template>
      <template v-slot:[`item.id`]="{ item, index }">
        <v-row no-gutters justify="center"></v-row>
        <span class="tableItem">{{ page * index + 1 }}</span>
      </template>
      <template v-slot:[`item.route`]="{ item }">
        <span class="tableItem pointer" style="color: #144fa9"
          >{{
            item?.route?.departure?.translations?.[0]?.name ||
            item?.route?.departure?.translations?.name
          }}
          -
          {{
            item?.route?.destination?.translations?.[0]?.name ||
            item?.route?.destination?.translations?.name
          }}</span
        >
      </template>
      <template v-slot:[`item.departed_at`]="{ item }">
        <div style="min-width: 150px">
          <span class="tableItem">
            <v-row no-gutters justify="center">
              {{
                new Date(item?.departed_at).toLocaleDateString("uk-UA", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  timeZone: "Europe/Kiev",
                })
              }}
            </v-row>
          </span>
        </div>
      </template>
      <template v-slot:[`item.arrival_at`]="{ item }">
        <div style="min-width: 135px">
          <v-row no-gutters justify="center">
            <span class="tableItem">
              {{
                new Date(item?.arrival_at).toLocaleDateString("uk-UA", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  timeZone: "Europe/Kiev",
                })
              }}</span
            >
          </v-row>
        </div>
      </template>
      <template v-slot:[`item.is_popular`]="{ item }">
        <div style="width: 140px">
          <v-row no-gutters align="center" justify="center">
            <v-switch inset v-model="item.is_popular" />
          </v-row>
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-menu offset-y rounded="lg">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="statusBox statusName"
              v-bind="attrs"
              v-on="on"
              style="width: 130px"
              :style="
                item.status == 'Active'
                  ? 'color: #149e51; background: rgba(20, 158, 81, 0.10); '
                  : item.status == 'Onroad'
                  ? 'color: #E19700; background: rgba(254, 213, 0, 0.10);'
                  : 'color: #DF3A3A; background: rgba(223, 58, 58, 0.10);'
              "
            >
              {{
                item.status == "Active"
                  ? "Активний"
                  : item.status == "Archive"
                  ? "Деактивовано"
                  : "В рейсі"
              }}
            </div>
          </template>
          <v-col style="padding: 10px; background-color: #fff">
            <div
              style="
                border-radius: 9px;
                background: #149e511a;
                color: #149e51;
                padding: 8px 16px;
                display: grid;
                place-items: center;
                width: 100%;
                margin-bottom: 10px;
              "
              class="statusName"
              v-if="item.status !== 'Active'"
              @click="changeTripStatus(item, 'Active')"
            >
              Активний
            </div>
            <div
              v-if="item.status !== 'Archive'"
              style="
                border-radius: 9px;
                background: rgba(223, 58, 58, 0.1);
                color: #df3a3a;
                padding: 8px 16px;
                display: grid;
                place-items: center;
              "
              class="statusName"
              @click="changeTripStatus(item, 'Archive')"
            >
              Деактивовано
            </div>
            <div
              v-if="item.status !== 'Onroad'"
              style="
                border-radius: 9px;
                background: rgba(254, 213, 0, 0.1);
                color: #e19700;
                padding: 8px 16px;
                display: grid;
                place-items: center;
                width: 100%;
                margin-top: 10px;
              "
              class="statusName"
              @click="changeTripStatus(item, 'Onroad')"
            >
              В рейсі
            </div>
          </v-col>
        </v-menu>
      </template>
      <template v-slot:[`item.seats_available`]="{ item }">
        {{ item.seats_available }}
      </template>
      <template v-slot:[`item.quantity_sell`]="{ item }">
        <div
          style="
            border-radius: 10px;
            background: rgba(20, 158, 81, 0.8);
            color: #fafafa;
            text-align: center;
            font-family: 'MacPaw Fixel';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            height: 30px;
            display: grid;
            place-items: center;
            padding-top: 2px;
            min-width: 90px;
          "
          :style="
            item.seats - item.seats_available == 0 ? 'background:  #989898' : ''
          "
        >
          <span>{{ item.seats - item.seats_available }}</span>
        </div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div style="min-width: 185px">
          <v-row
            no-gutters
            align="center"
            justify="end"
            v-if="$user_role == 'admin'"
          >
            <div
              class="iconBackground"
              style="margin-right: 12px; width: max-content; padding: 8px"
              @click="(showDetailTripDrawer = true), (detailTrip = item)"
            >
              <p class="detailBtnText">Детальніше</p>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="iconBackground"
                  @click="
                    $router.push({ name: 'editTrip', params: { id: item.id } })
                  "
                >
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="#828282"
                    class="pointer"
                    @click="
                      (symlinks = item.symlinks),
                        (showDetailSymlinkModal = true)
                    "
                    >mdi-dots-vertical</v-icon
                  >
                </div>
              </template>
              <span>Деталі</span>
            </v-tooltip>
          </v-row>
          <v-row no-gutters align="center" justify="end" v-else>
            <div
              class="iconBackground"
              style="margin-right: 12px; width: max-content; padding: 8px"
              @click="bookingTickets(item)"
              v-if="item.status == 'Active'"
            >
              <p class="detailBtnText">Бронювати</p>
            </div>
            <div class="iconBackground">
              <div class="notificationIcon" />
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="iconBackground" style="margin-left: 8px">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="#828282"
                    class="pointer"
                    @click="(showDetailTripDrawer = true), (detailTrip = item)"
                    >mdi-dots-vertical</v-icon
                  >
                </div>
              </template>
              <span>Деталі</span>
            </v-tooltip>
          </v-row>
        </div>
      </template>
    </v-data-table>
    <pagination
      :page="page"
      :quantityPage="quantityPage"
      @changePage="changePage"
    />
    <detail-trip-drawer
      style="margin-top: 5svh"
      v-if="showDetailTripDrawer"
      :trip="detailTrip"
      @close="showDetailTripDrawer = false"
      @editTrip="editTrip"
    />
  </v-col>
</template>
  
  <script>
import { mapActions } from "vuex";
import tripService from "../../../requests/Admin/tripService";
import Loader from "../../UI/Loader.vue";
import Pagination from "../../UI/pagination.vue";
import detailTripDrawer from "./detailTripDrawer.vue";
import debounce from "lodash/debounce";
import adminSearchScheduleService from "@/requests/Admin/adminSearchScheduleService";
//import routesService from "@/requests/admin/routesService";
//import requestFormData from "@/requests/requestFormData";
export default {
  components: { detailTripDrawer, Loader, Pagination },
  data: () => ({
    sortBy: "",
    sortDesc: "",
    headers: [
      {
        text: "№",
        align: "center",
        value: "id",
        sortable: false,
      },
      { text: "Маршрут", value: "route", align: "start", sortable: false },
      {
        text: "Дата віправлення",
        value: "departed_at",
        align: "start",
        sortable: true,
      },
      {
        text: "Дата прибуття",
        value: "arrival_at",
        align: "start",
        sortable: true,
      },
      {
        text: "Статус квитка",
        value: "status",
        align: "start",
        sortable: true,
      },
      {
        text: "Вільних місць",
        value: "seats_available",
        align: "center",
        sortable: true,
      },
      {
        text: "Продано",
        value: "quantity_sell",
        align: "center",
        sortable: false,
      },
      { text: "Опції", value: "action", sortable: false, align: "center" },
    ],
    showDetailSymlinkModal: false,
    showDetailTripDrawer: false,
    showLoader: true,
    detailTrip: {},
    trips: [],
    quantityPage: 0,
    page: 1,
    search: "",
  }),
  props: {
    searchValue: {
      require: false,
    },
  },
  mounted() {
    this.checkRole();
  },
  methods: {
    ...mapActions(["updateSchedule"]),
    changePage(page) {
      this.page = page;
      this.showLoader = true;
      this.getTrips();
    },
    sortTrips: debounce(function () {
      this.loading = true;
      this.getTrips();
    }, 300),
    async getTrips() {
      await tripService
        .getTrips(
          this.page,
          this.search,
          this.sortBy,
          this.sortDesc == true ? "DESC" : "ASC"
        )
        .then((res) => {
          if (res.status == "Success") {
            this.trips = res.data;
            this.quantityPage = res.pagination.meta.last_page;
            this.showLoader = false;
          }
        });
    },
    async changeTripStatus(item, status) {
      let form = new FormData();
      form.append("status", status);
      await tripService.changeTripStatus(item.id, form).then((res) => {
        if (res.status == "Success") {
          let index = this.trips.findIndex((trip) => trip.id == item.id);
          this.trips[index].status = status;
        }
      });
    },
    editTrip() {
      this.$emit("editTrip");
    },
    bookingTickets(schedule) {
      this.updateSchedule({
        ...schedule,
        departure: schedule?.route?.cities.find(
          (city) => city.station.city_uuid == this.$route.query.departureCity
        )?.station?.city,
        destination: schedule?.route?.cities.find(
          (city) => city.station.city_uuid == this.$route.query.arrivalCity
        )?.station?.city,
      });
      let recent_trip = this.$route.query;
      let trip = {};
      if (Object.values(recent_trip).length > 0) {
        trip = {
          departureCity: recent_trip.departureCity,
          arrivalCity: recent_trip.arrivalCity,
          start_date: recent_trip.startDate,
          end_date: recent_trip.endDate,
          qty: recent_trip.qty,
          trip_id: schedule.id,
        };
      } else {
        trip = {
          departureCity: schedule?.route?.cities?.[0]?.station?.city?.id,
          arrivalCity:
            schedule?.route?.cities?.[schedule?.route?.cities.length - 1]
              ?.station?.city?.id,
          start_date: schedule?.departed_at.substring(0, 10),
          end_date: schedule?.arrival_at.substring(0, 10),
          qty: 1,
          trip_id: schedule.id,
        };
      }

      sessionStorage.setItem("recent_trip_search_admin", JSON.stringify(trip));
      this.$router.push(`/admin/booking?qty=${this.$route?.query?.qty || 1}`);
    },
    async searchTrips() {
      let search = this.$route.query;
      await adminSearchScheduleService
        .searchSchedule(
          search.departureCity,
          search.arrivalCity,
          search.startDate,
          search.endDate,
          search.qty
        )
        .then((res) => {
          if (res.status == "Success") {
            this.trips = res.data;
            this.quantityPage = 1;
            this.showLoader = false;
          }
        });
    },
    setSearchField() {
      this.search = this.searchValue;
      this.showLoader = true;
      this.getTrips();
    },
    checkRole() {
      if (this.$user_role !== "dispatcher") {
        this.getTrips();
      } else {
        if (Object.values(this.$route.query).length > 0) {
          this.searchTrips();
        } else {
          this.getTrips();
        }
      }
    },
  },
  watch: {
    searchValue: {
      handler() {
        this.setSearchField();
      },
    },
    sortBy: {
      handler() {
        this.sortTrips();
      },
    },
    sortDesc: {
      handler() {
        this.sortTrips();
      },
    },
    "$route.query": {
      deep: true,
      handler() {
        this.showLoader = true;
        this.checkRole();
      },
    },
    "$route.path": {
      deep: true,
      handler() {
        this.showLoader = true;
        this.checkRole();
      },
    },
  },
};
</script>
  
  <style scoped>
.statusBox {
  border-radius: 10px;
  background: rgba(20, 158, 81, 0.1);
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
}
.statusName {
  color: #149e51;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: max-content;
  user-select: none;
  cursor: pointer;
}
.editIcon {
  width: 24px;
  height: 24px;
  background-color: #4b5262;
  -webkit-mask: url("../../../assets/img/iconsSvg/editIcon.svg") no-repeat
    center;
  mask: url("../../../assets/img/iconsSvg/editIcon.svg") no-repeat center;
  cursor: pointer;
}
.iconBackground:hover .editIcon {
  background-color: #fafafa;
}
.detailBtnText {
  color: #4b5262;
  text-align: center;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}
.iconBackground:hover .detailBtnText {
  color: #fff;
}
</style>
  <style>
.iconBackground:hover .theme--light.v-icon,
.theme--dark.v-icon {
  color: #fafafa !important;
}
</style>